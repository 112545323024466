import { useEffect, useState } from "react";
import Menu from "../components/Menu";
import { StatCard } from "../components/StatCard";
import { getTemporalData } from "../utils/OwnerDataGetter";
import FusionarCuenta from "../components/FusionarCuenta"
import {
  Shop,
  EnvelopeFill,
  PersonFill,
} from "react-bootstrap-icons";
import SessionOut from "../components/SessionOut";
import { useNavigate } from "react-router-dom";

export default function MyProfile() {
  const [sessionExpired, setSessionExpired] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [token, setToken] = useState(null)
  const rol = sessionStorage.getItem("rol");
  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);
  const navigate = useNavigate();
  const [profileData, setProfileData] = useState({
    nombre: "",
    username: "",
    email: "",
    tokens: []
  });
  useEffect(() => {
    const storedToken = sessionStorage.getItem("token");
    if (!storedToken) {
      console.error("No se encontró el token");
      navigate("/");
      return;
    }
    setToken(storedToken);
  }, [navigate]);

  useEffect(() => {
    if (token) {
      const fetch = async () => {
        const data = await getTemporalData(token)
        setProfileData(data);
      }
      fetch();
    }
  }, [token]);


  return (
    <div className="flex flex-col lg:flex-row h-full lg:h-screen bg-gray-100">
      {sessionExpired && <SessionOut />}
      <Menu rol={rol} token={token} />
      <main className="flex-1 p-4 md:p-8">
        <div className="flex flex-col md:flex-row justify-between items-center mb-4 md:mb-8">
          <h2 className="text-2xl md:text-3xl font-semibold text-gray-800">
            Mi Perfil
          </h2>
        </div>
        <div className="flex flex-col gap-6 mb-4 md:mb-8">
          <div className="flex flex-col lg:flex-row gap-6">

            <StatCard
              title={"Informacion Personal"}
              className="w-full lg:w-2/3 flex flex-col justify-center"
            >
              <div className="flex flex-col gap-5 text-lg md:text-xl">
                <div className="flex-grow flex items-center">
                  <PersonFill className="mr-2 h-7 w-7 md:h-9 md:w-9 text-gray-500 group-hover:text-pink-500" />
                  <span>{profileData.nombre}</span>
                </div>

                <div className="flex-grow flex items-center">
                  <EnvelopeFill className="mr-2 h-7 w-7 md:h-9 md:w-9 text-gray-500 group-hover:text-pink-500" />
                  <span>{profileData.email}</span>
                </div>

                <div className="flex-grow flex items-center">
                  <Shop className="mr-2 h-7 w-7 md:h-9 md:w-9 text-gray-500 group-hover:text-pink-500" />
                  <span>Restaurantes:</span>
                </div>
                <ul className="list-none pl-6">
                  {profileData.tokens.map((token, index) => (
                    <li key={index} className="flex items-start mb-2">
                      <svg
                        className="w-4 h-4 mr-2 mt-1 flex-shrink-0"
                        viewBox="0 0 24 24"
                        fill="currentColor"
                      >
                        <circle cx="5" cy="5" r="5" />
                      </svg>

                      <span>{token.nombre_restaurante}</span>
                    </li>
                  ))}
                </ul>
              </div>
            </StatCard>
            
          </div>
          <StatCard 
              title={"Fusionar Cuentas"}
            >
            <button
                  className="transition:all 
                            duration-300 
                            ease-in-out 
                            w-full
                            text-sm 
                            px-2 
                            py-1.5
                            ml-2 
                          text-white 
                          font-semibold 
                          bg-[#FF5EA3] 
                          rounded-md 
                          hover:bg-[#FF7DF2] 
                          hover:scale-105"
                  onClick={openModal}
                >
                  Quiero fusionar una cuenta
                </button>
            </StatCard>
        </div>
        <div id="modal-root"></div>
        <FusionarCuenta
          isModalOpen={isModalOpen}
          closeModal={closeModal}
        />
      </main>
    </div>
  );
}

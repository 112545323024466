
const getTemporalData = async (token) => {
    try {
        const response = await fetch('https://tippitqr.com/duenio/consulta-datos-temporales', {
            method: "POST",
            headers: { Authorization: `Bearer ${token}` },
        });
        if (!response.ok) {
            throw new Error(`${response.status}`)
        }
        const { password, ...restoDeAtributos } = await response.json();
        return restoDeAtributos
    } catch (e) {
        if (e.message.includes('401')) {
            window.location.href = '/';
        }
    }
}


const getMonthlyData = async (authToken, token, mes) => {
    try {
        const response = await fetch(`https://tippitqr.com/duenio/api/propinas/mes?mes=${mes}&token=${token}`, {
            method: "GET",
            headers: { Authorization: `Bearer ${authToken}` },
        });
        if (!response.ok) {
            throw new Error(`${response.status}`)
        }
        const data = await response.json();
        return data
    } catch (e) {
        if (e.message.includes('401')) {
            window.location.href = '/';
        }

    }
}
const getYearlyData = async (authToken, token, anio) => {
    try {
        const response = await fetch(`https://tippitqr.com/duenio/api/propinas/año-actual?año_actual=${anio}&token=${token}`, {
            method: "GET",
            headers: { Authorization: `Bearer ${authToken}` },
        });
        if (!response.ok) {
            throw new Error(`${response.status}`)
        }
        const data = await response.json();
        return data.propinas_año_actual
    } catch (e) {
        if (e.message.includes('401')) {
            window.location.href = '/';
        }

    }
}

export { getTemporalData, getMonthlyData, getYearlyData }
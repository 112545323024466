import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ClockFill, CupFill, DoorClosedFill, HouseFill, PeopleFill, PersonFill, QrCode, ThreeDots, XLg } from 'react-bootstrap-icons';

const Menu = ({ rol, token }) => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);

  

  const toggleMenu = () => setIsOpen(!isOpen);

  const handleNavigation = (e, path) => {
    e.preventDefault();
    navigate(path, { state: { token, rol } });
  };

  const renderMenuItems = () => (
    <nav className="rounded">
      <ul className="space-y-2">
        <li>
          <a href="#dashboard" onClick={(e) => handleNavigation(e, '/dashboard')} className="flex items-center hover:bg-white hover:text-black p-2 rounded">
            <HouseFill className='mt-1 mr-2' aria-hidden="true" />
            <span>Inicio</span>
          </a>
        </li>
        <li className="relative">
          {/* <a href="#turno" onClick={(e) => handleNavigation(e, '/turno')} className="flex items-center hover:bg-white hover:text-black p-2 rounded block">
              <ClockFill className='mr-2' aria-hidden="true" />
              <span>Registro de Turnos</span>
              {turnoActivo && (
                <ClockFill className="text-lg mr-1 mt-0.5 text-white bg-red-500 p-0.5 rounded-full absolute right-2 top-1 animate-blink" aria-hidden="true" />
              )}
            </a>
            <a href="#qrs" onClick={(e) => handleNavigation(e, '/misqrs')} className="flex items-center hover:bg-white hover:text-black p-2 rounded block mt-2">
              <QrCode className='mr-2' aria-hidden="true" />
              <span>Mis QRS</span>
            </a> */}
          <a href="#restaurante" onClick={(e) => handleNavigation(e, '/mirestaurante')} className="flex items-center hover:bg-white hover:text-black p-2 rounded mt-2">
            <CupFill className='mr-2' aria-hidden="true" />
            <span>Mi Restaurante</span>
          </a>
          <a href="#mipersonal" onClick={(e) => handleNavigation(e, '/mipersonal')} className="flex items-center hover:bg-white hover:text-black p-2 rounded mt-2">
            <PeopleFill className='mr-2' aria-hidden="true" />
            <span>Mi Personal</span>
          </a>
        </li>

        <li>
          <a href="#miPerfil" onClick={(e) => handleNavigation(e, '/miPerfil')} className="flex items-center hover:bg-white hover:text-black p-2 rounded mt-2">
            <PersonFill className='mr-2' aria-hidden="true" />
            <span>Ver Mi Perfil</span>
          </a>
        </li>
        <li>
          <a href="#salir" onClick={(e) => handleNavigation(e, '/')} className="flex items-center hover:bg-white hover:text-black p-2 rounded">
            <DoorClosedFill className='mr-2' aria-hidden="true" />
            <span>Salir</span>
          </a>
        </li>
      </ul>
    </nav>
  );

  return (
    <div className="flex bg-[#151424]">
      <div className="hidden lg:block lg:w-72 text-gray-200 text-sm font-sans font-semibold h-screen">
        <nav className="flex flex-col p-4 space-y-2">
          <img alt='logos' src="./Tippit-logo-Texto-1024x242.png" className='object-cover pr-5 pl-2 mb-10 mt-7' />
          {renderMenuItems()}
        </nav>
      </div>
      <div className="lg:hidden right-0 bottom-0 fixed z-50 drop-shadow-lg ">
        <button
          className="text-white bg-purple-500 p-2 rounded-full m-4"
          onClick={toggleMenu}
        >
          <ThreeDots className='text-4xl' />
        </button>
      </div>
      {isOpen && (
        <>
          <div className="fixed inset-0 bg-black opacity-80 z-40" onClick={toggleMenu}></div>
          <div className={`lg:hidden fixed top-0 left-0 bg-gray-100 text-gray-700 w-64 h-full z-50 transform transition-transform ease-in-out duration-3500 ${isOpen ? 'translate-x-0' : '-translate-x-full'} sliding-menu`}>
            <nav className="flex flex-col p-4 space-y-2">
              <button
                className="self-end text-gray-600 font-bold p-1 rounded-full"
                onClick={toggleMenu}
              >
                <XLg />
              </button>
              {renderMenuItems()}
            </nav>
          </div>
        </>
      )}
    </div>
  );
};

export default Menu;

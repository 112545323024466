import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import RestauranteDashboard from "./RestauranteDashboard";
import Menu from "../components/Menu";

function Dashboard() {
  const navigate = useNavigate();
  const [token, setToken] = useState(null);
  const [rol, setRol] = useState(null);

  useEffect(() => {
    const storedToken = sessionStorage.getItem("token");
    if (!storedToken) {
      console.error("No se encontró el token");
      navigate("/");
      return;
    }
    setToken(storedToken);

    const role = sessionStorage.getItem("rol");
    if (!role) {
      console.error("No se encontró el rol");
      navigate("/");
      return;
    }
    setRol(role);
  }, [navigate]);

  useEffect(() => {
    const checkRole = () => {
      const role = sessionStorage.getItem("rol");
      if (!role) {
        navigate("/");
      }
    };

    const timeout = setTimeout(checkRole, 1500);
    return () => clearTimeout(timeout);
  }, [navigate]);

  useEffect(() => {
    if (process.env.NODE_ENV === 'development') {
      console.log("Token in Dashboard:", token);
    }
  }, [token, rol]);

  return (
    <div className="flex">
      <Menu rol={rol} token={token} />
      <div className="flex-1 w-full">
        {rol === "Duenio" ? (
          <RestauranteDashboard token={token} />
        ) : (
          <div>Usuario no autorizado</div>
        )}
      </div>
    </div>
  );
}

export default Dashboard;

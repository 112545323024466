import React, { useEffect, useState, useCallback, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Menu from "../components/Menu";
import SessionOut from "../components/SessionOut";
import FormSwitch from "../components/FormSwitch";
import CalendarTurnos from "../components/CalendarTurnos";

const Turno = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [token, setToken] = useState(null);
  const [sessionExpired, setSessionExpired] = useState(false);
  const [status, setStatus] = useState(null);
  const [mozos, setMozos] = useState([]);
  const [selectedMozos, setSelectedMozos] = useState([]);
  const [montoEfectivo, setMontoEfectivo] = useState("");
  const [finalizarResponse, setFinalizarResponse] = useState(null);
  const [showCreateForm, setShowCreateForm] = useState(false);
  const [turnoTiempo, setTurnoTiempo] = useState("");
  const [backup, setBackup] = useState(false);
  const [switchCount, setSwitchCount] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const draggedElementRef = useRef(null);
  const [error, setError] = useState(null);
  const [draggedItem, setDraggedItem] = useState(null);
  const [draggedOverArea, setDraggedOverArea] = useState(null);
  const [draggedItemRole, setDraggedItemRole] = useState(null);

  const [items, setItems] = useState({
    Presentes: [],
  });

  const currentToken = location.state?.token;

  const checkTurnoActivo = useCallback(async () => {
    try {
      if (!currentToken) {
        return;
      }

      const response = await fetch(
        "https://tippitqr.com/userdb/hay_activo",
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${currentToken}`,
          },
        }
      );

      const data = await response.json();

      setStatus(data.status);

      if (response.status === 200) {
        setShowCreateForm(true); // request success = turno no encontrado
      } else if (response.status === 401) {
        setSessionExpired(true);
      } else if (response.status === 400) {
        setShowCreateForm(false); // bad request = turno encontrado
      } else {
        console.log("Error:", data);
      }
    } catch (error) {
      console.error("Error checking turno activo:", error);
    }
  }, [currentToken]);

  const fetchMozos = useCallback(async () => {
    try {
      const response = await fetch(
        "https://tippitqr.com/userdb/mozos_vinculados",
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${currentToken}`,
          },
        }
      );

      const data = await response.json();

      if (response.ok) {
        setMozos(data);
      } else {
        console.log("Error fetching mozos:", data);
      }
    } catch (error) {
      console.error("Error fetching mozos:", error);
    }
  }, [currentToken]);

  useEffect(() => {
    let currentToken = location.state?.token;
    if (!currentToken) {
      currentToken = sessionStorage.getItem("token");
    } else {
      sessionStorage.setItem("token", currentToken);
    }

    if (!currentToken) {
      navigate("/");
    } else {
      setToken(currentToken);
    }
  }, [navigate, location.state]);

  const backupTurnos = async () => {
    if (token) {
      try {
        const response = await fetch(
          "https://tippitqr.com/checkear/backup-turnos",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({ token: token.toString() }),
          }
        );

        const data = await response.json();

        if (response.status === 200) {
          setShowModal(true);
        } else if (response.status === 400) {
          console.log("No existing backups para el dia de hoy");
        } else {
          if (process.env.NODE_ENV === "development") {
            console.log(data.status);
          }
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
  };

  // HANDLE DRAG START
  const handleDragStart = (e, mozo) => {
    const target = e.currentTarget;

    // Asigna datos a los atributos data-*
    target.dataset.cuit = mozo.cuit;
    target.dataset.nombre = mozo.nombre;
    target.dataset.foto = mozo.foto;
    target.dataset.rol = mozo.rol;

    // Verifica el estado del checkbox antes de proceder
    const checkbox = document.getElementById(`checkbox-${mozo.cuit}`);
    if (checkbox && !checkbox.checked) {
      console.log(
        `El rol del elemento que se está arrastrando es: ${mozo.rol}`
      );
    }

    // Configura el dataTransfer con datos JSON
    const mozoData = JSON.stringify({
      cuit: mozo.cuit,
      nombre: mozo.nombre,
      foto: mozo.foto,
      rol: mozo.rol,
    });

    e.dataTransfer.setData("application/json", mozoData);
    e.dataTransfer.effectAllowed = "move";

    target.classList.add("dragging");

    draggedElementRef.current = target;
    document.getElementById("drag-area").classList.add("drag-here");
  };

  // HANDLE DRAG END
  const handleDragEnd = (e) => {
    const target = e.currentTarget;
    target.classList.remove("dragging");

    const cuit = target.dataset.cuit;
    const checkbox = document.getElementById(`checkbox-${cuit}`);

    if (checkbox && checkbox.checked) {
      const role = target.dataset.rol;
      const rolesContainers = Array.from(
        document.querySelectorAll("[id^='grupo-']")
      );

      const originalContainer = rolesContainers.find(
        (group) => group.dataset.rol === role
      );

      if (process.env.NODE_ENV === "development") {
        console.log(
          "elstored",
          originalContainer ? originalContainer.id : "null"
        );
      }

      if (originalContainer && !originalContainer.contains(target)) {
        originalContainer.appendChild(target);
      }
    }

    setDraggedItem(null);
    setDraggedItemRole(null);
    draggedElementRef.current = null;
  };

  // HANDLE DROP
  const handleDrop = (e) => {
    e.preventDefault();
    const cuit = draggedElementRef.current.dataset.cuit;
    const role = draggedElementRef.current.dataset.rol;
    const originalContainer = document.getElementById(`grupo-${role}`);
    const dragArea = document.getElementById("drag-area");

    if (draggedElementRef.current) {
      const droppedOn = e.target;
      let currentElement = droppedOn;

      while (currentElement && currentElement !== document.body) {
        if (currentElement.classList.contains("draggable-item")) {
          if (originalContainer) {
            originalContainer.appendChild(draggedElementRef.current);
            const checkbox = document.getElementById(
              `checkbox-${draggedElementRef.current.dataset.cuit}`
            );
            if (checkbox) {
              checkbox.checked = false;
              handleMozosChange(false, draggedElementRef.current.dataset.cuit);
            }
          }
          return;
        }
        currentElement = currentElement.parentNode;
      }

      const rolesContainers = Array.from(
        document.querySelectorAll("[id^='grupo-']")
      );
      if (rolesContainers.length === 0) {
        if (process.env.NODE_ENV === "development") {
          console.log("No hay roles presentes");
        }
        return;
      }

      const checkbox = document.getElementById(
        `checkbox-${draggedElementRef.current.dataset.cuit}`
      );

      if (checkbox && checkbox.checked) {
        if (rolesContainers.some((group) => droppedOn.contains(group))) {
          checkbox.checked = false;
          handleMozosChange(false, cuit);
          if (
            originalContainer &&
            !originalContainer.contains(draggedElementRef.current)
          ) {
            originalContainer.appendChild(draggedElementRef.current);
            if (process.env.NODE_ENV === "development") {
              console.log("Elemento devuelto a su contenedor original");
            }
          }
        }
        return;
      }

      if (
        !droppedOn.contains(draggedElementRef.current) &&
        draggedElementRef.current !== droppedOn
      ) {
        if (rolesContainers.some((group) => group.contains(droppedOn))) {
          if (checkbox) {
            checkbox.checked = false;
            handleMozosChange(false, cuit);
          }
        } else {
          droppedOn.appendChild(draggedElementRef.current);
        }
      }

      if (
        !dragArea.contains(droppedOn) &&
        dragArea.contains(draggedElementRef.current)
      ) {
        if (originalContainer) {
          originalContainer.appendChild(draggedElementRef.current);
          const checkbox = document.getElementById(
            `checkbox-${draggedElementRef.current.dataset.cuit}`
          );
          if (checkbox) {
            checkbox.checked = false;
            handleMozosChange(false, draggedElementRef.current.dataset.cuit);
          }
        }
      }

      document.getElementById("drag-area").classList.remove("drag-here");

      const movedElement = draggedElementRef.current.dataset;
      const data = {
        cuit: movedElement.cuit,
        nombre: movedElement.nombre,
        foto: movedElement.foto,
        rol: movedElement.rol,
      };

      if (process.env.NODE_ENV === "development") {
        console.log(data);
      }

      setItems((prevItems) => {
        const newItems = { ...prevItems, Presentes: [...prevItems.Presentes] };

        Object.keys(newItems).forEach((role) => {
          if (newItems[role]) {
            newItems[role] = newItems[role].filter(
              (item) => item.cuit !== data.cuit
            );
          }
        });

        if (!newItems.Presentes.some((item) => item.cuit === data.cuit)) {
          newItems.Presentes.push(data);
        }

        handleMozosChange(true, data.cuit);

        if (
          checkbox &&
          !rolesContainers.some((group) => droppedOn.contains(group))
        ) {
          checkbox.checked = true;
        } else if (checkbox) {
          checkbox.checked = false;
          handleMozosChange(false, data.cuit);
        }

        return newItems;
      });

      draggedElementRef.current = null;
    }

    setDraggedItem(null);
    setDraggedItemRole(null);
  };

  /// HANDLES EN DESHUSO
  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDragLeave = () => {
    setDraggedOverArea(null);
  };

  const TurnoButtonClick = async () => {
    await backupTurnos();
  };

  const finalizarTurno = async () => {
    try {
      const mozosString = selectedMozos.join(",");

      const montoEfectivoFinal = montoEfectivo === "" ? 0 : montoEfectivo;

      if (process.env.NODE_ENV === "development") {
        console.log("Mozos:", mozosString);
        console.log("Monto Efectivo:", montoEfectivoFinal);
      }

      const bodyData = new URLSearchParams({
        mozos: mozosString,
        monto_efectivo: montoEfectivoFinal,
      }).toString();

      if (process.env.NODE_ENV === "development") {
        console.log("Request Body:", bodyData);
      }

      const response = await fetch(
        "https://tippitqr.com/finalizar/turno",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Authorization: `Bearer ${token}`,
          },
          body: bodyData,
        }
      );

      const data = await response.json();

      if (response.status === 200) {
        setFinalizarResponse(data);
        setStatus(data.mensaje);
        setTurnoTiempo("");
        localStorage.removeItem("Turno");
        setShowModal(true);
        setError(null);
      } else {
        console.log(data);
        setError(`${data.error}`);
      }
    } catch (error) {
      setError(`Error al finalizar el turno: ${error}`);
    }
  };

  useEffect(() => {
    checkTurnoActivo();
  }, [checkTurnoActivo]); // Ejecutar checkTurnoActivo cuando cambie (SI!)

  useEffect(() => {
    if (status === "Ya existe un turno activo") {
      fetchMozos();
    }
  }, [status, fetchMozos]);

  const crearTurno = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch("https://tippitqr.com/crear/turno", {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: `Bearer ${token}`,
        },
        body: new URLSearchParams({
          hora_fin: "12",
          backup: backup.toString(),
        }).toString(),
      });

      const data = await response.json();

      if (response.status === 200) {
        if (process.env.NODE_ENV === "development") {
          console.log("Turno creado exitosamente!", data);
        }
        setStatus(data.status);
        setShowCreateForm(false); // Ocultar el formulario de creación después de crear el turno
        localStorage.setItem("Turno", new Date().toISOString()); // Guardar la hora actual en sessionStorage
        setTimeout(() => {
          setStatus(null);
          checkTurnoActivo();
        }, 500);
      } else {
        console.log("Error al crear el turno:", data);
      }
    } catch (error) {
      console.error("Error creando turno:", error);
    }
  };

  const handleMozosChange = (value, cuit) => {
    if (value) {
      setSwitchCount((prevCount) => prevCount + 1); // Incrementa el contador si se activa el switch
      setSelectedMozos((prevState) => [...prevState, cuit]);
    } else {
      setSwitchCount((prevCount) => prevCount - 1); // Decrementa el contador si se desactiva el switch
      setSelectedMozos((prevState) =>
        prevState.filter((item) => item !== cuit)
      );
    }
  };

  useEffect(() => {
    const calculateTimeElapsed = () => {
      const turnoInicio = localStorage.getItem("Turno");
      if (!turnoInicio) return;

      const turnoInicioDate = new Date(turnoInicio);
      const ahora = new Date();
      const diferencia = ahora - turnoInicioDate;

      const horas = Math.floor(diferencia / (1000 * 60 * 60));
      const minutos = Math.floor((diferencia % (1000 * 60 * 60)) / (1000 * 60));
      const segundos = Math.floor((diferencia % (1000 * 60)) / 1000);

      setTurnoTiempo(`${horas}h ${minutos}m ${segundos}s`);
    };

    calculateTimeElapsed();
    const interval = setInterval(calculateTimeElapsed, 1000);

    return () => clearInterval(interval);
  }, []);

  const handleAgregarTodos = () => {
    const dragArea = document.getElementById("drag-area");
    const groups = document.querySelectorAll("[id^=grupo-]");

    if (dragArea) {
      groups.forEach((group) => {
        const elements = Array.from(group.children);
        elements.forEach((element) => {
          dragArea.appendChild(element);
          const checkbox = document.getElementById(
            `checkbox-${element.dataset.cuit}`
          );
          if (checkbox) {
            checkbox.checked = true;
            handleMozosChange(true, element.dataset.cuit);
          }
        });
      });
    }
  };

  const handleQuitarTodos = () => {
    const dragArea = document.getElementById("drag-area");
    const groups = document.querySelectorAll("[id^=grupo-]");

    if (dragArea) {
      const elements = Array.from(dragArea.children);
      elements.forEach((element) => {
        const role = element.dataset.rol;
        const group = Array.from(groups).find((g) => g.id === `grupo-${role}`);

        if (group) {
          group.appendChild(element);
          const checkbox = document.getElementById(
            `checkbox-${element.dataset.cuit}`
          );
          if (checkbox) {
            checkbox.checked = false;
            handleMozosChange(false, element.dataset.cuit);
          }
        }
      });
    }
  };

  const handleMozoClick = (e) => {
    const clickedElement = e.currentTarget;
    const dragArea = document.getElementById("drag-area");

    // Obtener el rol dinámicamente
    const role = clickedElement.dataset.rol;
    const isInDragArea = dragArea.contains(clickedElement);

    // Seleccionar dinámicamente el contenedor basado en el rol
    const targetGroup = document.getElementById(`grupo-${role}`);

    if (isInDragArea) {
      // Mover de vuelta al contenedor original
      if (targetGroup) {
        targetGroup.appendChild(clickedElement);
      }
      const checkbox = document.getElementById(
        `checkbox-${clickedElement.dataset.cuit}`
      );
      if (checkbox) {
        checkbox.checked = false;
        handleMozosChange(false, clickedElement.dataset.cuit);
      }
    } else {
      // Mover al dragArea
      if (dragArea) {
        dragArea.appendChild(clickedElement);
        const checkbox = document.getElementById(
          `checkbox-${clickedElement.dataset.cuit}`
        );
        if (checkbox) {
          checkbox.checked = true;
          handleMozosChange(true, clickedElement.dataset.cuit);
        }
      }
    }
  };

  const addDollarSigns = (text) => {
    return text.replace(/\b(\d+(\.\d+)?)\b/g, "$$$1");
  };

  return (
    <div className="w-full min-h-screen bg-gradient-to-b from-pink-500 via-pink-200 to-gray-300 md:bg-none">
      <div className="flex items-left justify-left w-90 text-sm ">
        <div className="flex w-full">
          <Menu rol="Restaurante" token={token} />
          <div className="flex-1 w-full">
            <div
              className={`w-11/12 max-w-[900px] mb-10 pt-8 mt-10 px-8 pb-5 min-h-96 mx-auto bg-white shadow-lg shadow-gray-250 rounded-lg relative ${turnoTiempo
                  ? "border-solid border-2 border-red-500 max-w-[900px]"
                  : ""
                }`}
            >
              <h1 className="text-2xl font-semibold mb-2">
                Registro de Turnos
              </h1>
              <p className="text-md md:text-lg">
                Esta herramienta te permite registrar y finalizar turnos para
                calcular la propina a repartir entre los mozos en partes iguales
                y proporcionales.
              </p>
              <h3 className="text-md font-bold mt-3 md:absolute top-3 right-8">
                Turnos en progreso:
                <span
                  className={`rounded-md md:ml-2 font-normal block md:inline ${status === "Ya existe un turno activo"
                      ? "text-red-500 "
                      : "text-green-500"
                    }`}
                >
                  {status}
                  <span
                    className={`bg-red-500 text-white rounded-md text-xl font-semibold px-3 md:ml-3 inline-block min-w-[140px] text-center ${turnoTiempo ? "" : "hidden"
                      }`}
                  >
                    {turnoTiempo}
                  </span>
                </span>
              </h3>

              <hr className="my-5 mx-[-30px]" />
              {showCreateForm && (
                <div className="mt-4">
                  <h3 className="text-lg mb-2 font-semibold">Nuevo turno</h3>
                  <form onSubmit={crearTurno}>
                    <div className="flex flex-row space-x-4 mb-4">
                      <div className="w-50 mr-4">
                        <label className="block text-gray-700 text-sm font-bold mb-2">
                          Hora de cierre
                        </label>
                        <input
                          type="time"
                          name="hora_fin"
                          defaultValue="12:00"
                          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          required
                        />
                      </div>
                    </div>
                    <button
                      type="submit"
                      className="transition:all 
                        duration-300 
                        ease-in-out 
                        w-full 
                        px-2 
                        py-4 
                        text-white 
                        font-semibold 
                        text-lg
                        bg-[#FF5EA3] 
                        rounded-lg 
                        hover:bg-[#FF7DF2] 
                        p-3"
                      onClick={TurnoButtonClick}
                    >
                      Comenzar nuevo turno
                    </button>
                    <p className="font-normal text-center mt-2 text-sm text-gray-500">
                      El sistema no realiza transacciones, solo ayuda a calcular
                      y visualizar cuánto le corresponde a cada mozo
                    </p>
                  </form>
                </div>
              )}

              {showModal && (
                <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-90 z-50">
                  <div className="bg-white pt-6 rounded-lg shadow-lg text-center w-6/12 max-w-96 min-w-[355px] p-4">
                    <h2 className="text-xl font-bold">
                      Transferencias Pendientes
                    </h2>
                    <p className="mb-4 text-md">
                      Existen transferencias pendientes. ¿Quieres agregarlas
                      también?
                    </p>
                    <div className="flex justify-center space-x-4">
                      <button
                        onClick={() => {
                          setBackup(true);
                          setShowModal(false);
                        }}
                        className="transition-all duration-300 ease-in-out px-4 py-2 text-white font-semibold bg-[#FF5EA3] rounded-lg hover:bg-[#FF7DF2] hover:scale-105"
                      >
                        Sí, agregarlas
                      </button>
                      <button
                        onClick={() => {
                          setBackup(false);
                          setShowModal(false);
                        }}
                        className="transition-all duration-300 ease-in-out px-4 py-2 text-white font-semibold bg-gray-500 rounded-lg hover:bg-gray-700 hover:scale-105"
                      >
                        No
                      </button>
                    </div>
                  </div>
                </div>
              )}

              {status && !showCreateForm && !finalizarResponse && (
                <div className="mt-4">
                  <h2 className="text-2xl mb-2 text-gray-600 font-semibold">
                    Finalizar turno activo
                  </h2>
                  <p className="text-md md:text-lg mb-5">
                    Antes de comenzar un turno, primero tenes que terminar
                    cualquier turno que este corriendo en la actualidad. Agrega
                    el personal que trabajo en este turno para poder
                    finalizarlo.
                  </p>

                  <div className="flex flex-col lg:flex-row flex-wrap mt-8">
                    {mozos.length > 0 ? (
                      <div
                        className="w-full lg:w-1/2 mb-4 pr-4"
                        id="dragStart"
                        onDragOver={(e) => e.preventDefault()}
                        onDrop={(e) => handleDrop(e)}
                      >
                        {mozos.map((grupo) => (
                          <div key={grupo.rol} className="mb-4">
                            <h4 className="font-semibold text-gray-800">
                              {grupo.rol}
                            </h4>
                            <div
                              className="flex flex-wrap min-h-20"
                              id={`grupo-${grupo.rol}`}
                            >
                              {grupo.usuarios.map((mozo) => (
                                <div
                                  key={mozo._id}
                                  data-cuit={mozo.cuit}
                                  data-rol={mozo.rol}
                                  className="flex mb-2 w-1/7 lg:w-1/3 xl:w-[85px] 2xl:w-[90px] draggable-item h-fit bg-transparent"
                                  draggable
                                  onDragStart={(e) => handleDragStart(e, mozo)}
                                  onDragEnd={handleDragEnd}
                                  onClick={handleMozoClick}
                                >
                                  <span className="">
                                    <input
                                      type="checkbox"
                                      id={`checkbox-${mozo.cuit}`} // Asigna un id único
                                      checked={selectedMozos.includes(
                                        mozo.cuit
                                      )}
                                      onChange={(e) =>
                                        handleMozosChange(
                                          e.target.checked,
                                          mozo.cuit
                                        )
                                      }
                                      className="form-checkbox invisible"
                                    />
                                  </span>
                                  <div className="text-center">
                                    <img
                                      src={mozo.foto}
                                      onError={(e) => {
                                        e.currentTarget.src =
                                          "./Tippit-logo-cuadrado-trasparente-300x300.png";
                                      }}
                                      className="rounded-full object-cover w-32 h-20 border-3"
                                      alt={mozo.nombre}
                                      draggable="false"
                                    />
                                    <label className="text-gray-700 capitalize">
                                      {mozo.nombre}
                                    </label>
                                  </div>
                                </div>
                              ))}
                            </div>
                          </div>
                        ))}
                      </div>
                    ) : (
                      <p className="w-full">
                        Por favor vincular mozos antes de utilizar esta
                        herramienta.
                      </p>
                    )}

                    <div className="w-full lg:w-1/2 mb-4">
                      <h4 className="font-sans text-lg text-center font-semibold text-gray-800 -z-1 mt-[-10px]">
                        Personal que trabajo el día de hoy
                      </h4>

                      <div
                        className="flex flex-wrap justify-start border-dashed border-4 p-5 border-gray-300 rounded-lg h-full min-h-[300px] dragged-area"
                        id="drag-area"
                        onDragOver={(e) => e.preventDefault()}
                        onDrop={(e) => handleDrop(e)}
                      ></div>
                    </div>
                  </div>

                  <div className="text-center mt-5">
                    <button
                      className="border rounded-lg px-5 p-3 mr-2 transition:all 
                duration-300 
                ease-in-out 
                
                py-3 
              text-white 
                font-semibold 
                bg-[#FF5EA3] 
              hover:bg-[#FF7DF2] 
                mt-4"
                      onClick={handleAgregarTodos}
                    >
                      Agregar todos
                    </button>
                    <button
                      className="border rounded-lg px-5 p-3 transition:all 
                duration-300 
                ease-in-out 
                
                py-3 
              text-white 
                font-semibold 
                bg-[#da4560] 
              hover:bg-[#6e2439] 
                mt-4"
                      onClick={handleQuitarTodos}
                    >
                      Quitar todos
                    </button>
                  </div>

                  <div className="mb-6 text-center">
                    <label className="block text-gray-500 text-lg mt-10 font-bold mb-2">
                      Monto en Efectivo
                    </label>
                    $
                    <input
                      type="number"
                      value={montoEfectivo === "" ? 0 : montoEfectivo}
                      min="0"
                      onChange={(e) =>
                        setMontoEfectivo(
                          e.target.value === "" ? 0 : Number(e.target.value)
                        )
                      }
                      onFocus={(e) => e.target.select()}
                      className={`shadow appearance-none rounded w-60 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${parseFloat(montoEfectivo) <= 0
                          ? "border-red-500"
                          : "border"
                        }`}
                    />
                  </div>
                  <button
                    onClick={finalizarTurno}
                    disabled={montoEfectivo < 0 || switchCount < 2}
                    className="transition-all 
                    duration-300 
                    ease-in-out 
                    w-full 
                    px-2 
                    py-4 
                    disabled:bg-gray-500
                    text-white 
                    font-semibold 
                    bg-red-500 
                    rounded-lg 
                    hover:bg-red-800 "
                  >
                    Finalizar Turno
                  </button>
                  <p className="text-red-700 text-center mt-1">{error}</p>
                </div>
              )}

              {showModal && (
                <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-90 z-50">
                  <div className="bg-white pt-6 rounded-lg shadow-lg text-left w-6/11 max-w-90 min-w-[355px] p-4">
                    <h3 className="text-lg mb-2 font-semibold">
                      Resultado del turno finalizado:
                    </h3>
                    <p className="text-gray-700 text-lg">
                      Monto Efectivo: ${finalizarResponse.Monto.efectivo}
                    </p>
                    <p className="text-gray-700 text-lg">
                      {finalizarResponse.detalles.map((detalle, index) => (
                        <span key={index} className="block">
                          {addDollarSigns(detalle)}
                        </span>
                      ))}
                    </p>
                    <div className="text-center">
                      <button
                        onClick={() => {
                          setShowModal(false);
                          window.location.reload();
                        }}
                        className="transition-all duration-300 ease-in-out px-4 py-2 text-white font-semibold bg-gray-500 rounded-lg hover:bg-gray-700 hover:scale-105 mt-4"
                      >
                        Cerrar
                      </button>
                    </div>
                  </div>
                </div>
              )}

              <CalendarTurnos />

              {sessionExpired && <SessionOut />}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Turno;

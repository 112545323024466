import React, { useState, useEffect } from 'react';
import Modal from '../utils/Modal';

export default function FusionarCuenta({ isModalOpen, closeModal }) {
    const [userName, setUserName] = useState('');
    const [success, setSuccess] = useState(false)
    const [password, setPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const token = sessionStorage.getItem('token');


    const close = () => {
        setErrorMessage("");
        setUserName("");
        setPassword("");
        closeModal()
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        setErrorMessage(''); // Limpiar mensaje de error en cada envío
        try {
            const formData = new URLSearchParams();
            formData.append('username', userName);
            formData.append('password', password);
            //console.log(userName,newUsername,password)

            const response = await fetch('https://tippitqr.com/duenio/fusionar-restaurantes', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    Authorization: `Bearer ${token}`
                },
                body: formData.toString(),
            });

            if (response.ok) {
                console.log('Cuenta activado exitosamente');
                setSuccess(true)
                setTimeout(() => {
                    window.location.reload();
                }, 3500);
            } else {
                // Maneja el error
                const errorText = await response.text();
                setErrorMessage(errorText);
            }
        } catch (error) {
            console.error('Error al enviar el formulario:', error);
            setErrorMessage('Error al enviar el formulario: ' + error.message);
        }
    }
    return (
        <Modal isOpen={isModalOpen} onClose={close}>
            {!success ? (
                <div className="max-w-[600px] p-4">
                    <h2 className='text-xl font-bold mb-1'>Une tus cuentas y administra tus restaurantes en una sola cuenta!</h2>
                    <h3>Ingresa los datos de la cuenta que quieras añadir a esta.</h3>
                    <p className='my-2 text-gray-500'>(NOTA: una vez añadida la cuenta, se borrara y no podras acceder mas a ella.)</p>

                    <p className='mb-3 bg-gray-100 px-2 text-gray-700 py-1 rounded-md mt-5'>Ingresa los datos:</p>

                    <form onSubmit={handleSubmit}>
                        <div>
                            <label htmlFor="name" className='text-sm ml-2 block'>Nombre de usuario:</label>
                            <input
                                type="text"
                                id="name"
                                value={userName}
                                onChange={(e) => setUserName(e.target.value)}
                                required
                                className='border block mb-5 p-3 w-full rounded-md text-lg mono text-center'
                            />
                        </div>

                        <div>
                            <label htmlFor="password" className='text-sm ml-2 block'>constraseña:</label>
                            <input
                                type="text"
                                id="password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                minLength={8}
                                required
                                className='border block mb-5 p-3 w-full rounded-md text-lg mono text-center'
                            />
                            {password.length < 8 && password.length !== 0 && <p className='text-sm text-red-500 text-center'>La contraseña tiene que tener minimo 8 caracteres</p>}
                        </div>
                        <button type="submit" className="
                            transition:all 
                            duration-300 
                            ease-in-out 
                            w-full 
                            px-2 
                            py-4 
                          text-white 
                            font-semibold 
                          bg-[#FF5EA3] 
                          rounded-lg 
                          hover:bg-[#FF7DF2] 
                          hover:scale-105 p-3">Fusionar cuenta</button>
                        {errorMessage && (
                            <p className="text-red-500 mt-3 text-center">{errorMessage}</p>
                        )}
                    </form>
                </div>
            ) : (
                <div className="max-w-1/2 p-4">
                    <h2 className='text-xl font-bold mb-2'>Cuenta fusionada Exitosamente</h2>
                    <p>Tu cuenta fue fusionada con éxito! Ahora todos los datos de esa cuenta los puedes administrar aqui.</p>
                </div>
            )}
        </Modal>
    )
}
import React from "react";
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import "./App.css";
import Login from "./pages/Login";
import Dashboard from "./pages/Dashboard";
import MisQRS from "./pages/MisQRS";
import MiRestaurante from "./pages/MiRestaurante";
import Turno from "./pages/Turno";
import MiPersonal from "./pages/MiPersonal";
import MyProfile from "./pages/MyProfile";
function App() {
  return (
    <div className="App">
      {/* <div id="modal-root"/> */}
      <Router>
        <Routes>
          <Route path="/" element={<Login />} /> 
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/misqrs" element={<MisQRS />} />
          <Route path="/mirestaurante" element={<MiRestaurante />} />
          <Route path="/turno" element={<Turno />} /> 
          <Route path="/MiPersonal" element={<MiPersonal />} />
          <Route path="/miPerfil" element={<MyProfile/>}/>
        </Routes>
      </Router>
    </div>
  );
}

export default App;
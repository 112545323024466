export const StatCard = ({ title, children, id }) => (
    <div className="border border-gray-300 rounded-lg py-3 px-5 text-center text-gray-500 flex-grow transition-shadow duration-300 hover:shadow-lg group hover:border-pink-500 bg-white">
        <h2 className="text-2xl font-bold mb-2 text-gray-500 group-hover:text-pink-500 transition-colors duration-300">
            {title}
        </h2>
        <div className="flex flex-col relative overflow-hidden ">
            <div id={id} className="absolute inset-0 bg-white flex items-center justify-center fade-out z-10">
                <div className="text-2xl text-gray-500">
                    <img
                        src="/gooey-balls-2.svg"
                        className="mx-auto filter invert"
                        draggable="false"
                        alt=""
                    />
                </div>
            </div>
            {children}
        </div>
    </div>
);
import { StarFill } from "react-bootstrap-icons";
import Chart from "react-apexcharts";

export default function Mozo(props) {
    const { pos, mozoDetails, groupedPaymentsArray } = props
    return(
        <div className="flex flex-col md:flex-row items-start mb-5 gap-4">
                  <div className="flip-container hover mb-5 w-full h-[440px] md:w-[310px] md:h-[420px] xl:h-[430px]">
                    <div className="flip-card">
                      <div className="flip-card-inner">
                        <div className="flip-card-front rounded-xl shadow-gray-400 shadow-md bg-gradient-to-tr from-purple-600 to-blue-600 flex justify-center items-center">
                          <StarFill size={90} color="#fff" />
                        </div>

                        <div className="flip-card-back text-white rounded-xl p-4 mb-5 shadow-gray-400 shadow-md bg-gradient-to-tr from-purple-600 to-blue-600">
                          <img
                            src={mozoDetails[pos]?.photo}
                            className="img-fluid rounded-lg w-full h-auto shadow max-w-[350px] max-h-[350px]"
                            alt=""
                          />
                          <h3 className="rounded-md p-0 text-md font-semibold mt-2">
                            {mozoDetails[pos]?.mostFrequentMozo}
                          </h3>
                          <h3 className="text-sm">
                            Mejor Propina: $
                            {mozoDetails[pos]?.highestMonto.toFixed()}
                          </h3>
                          <h3 className="text-sm">
                            Calificación Promedio:{" "}
                            {mozoDetails[pos]?.averageCalificacion.toFixed(1)}
                          </h3>
                          <h3 className="text-sm">
                            Propina Promedio: $
                            {mozoDetails[pos]?.averageMonto !== undefined
                              ? mozoDetails[pos]?.averageMonto.toFixed(2)
                              : "No hay datos"}
                          </h3>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="hidden md:block md:flex-grow">
                    <div id="charts-container">
                      <div className="chart mx-auto text-center">
                        <h3 className="text-md text-gray-500">Pagos Totales</h3>
                        <h3 className="text-xl font-semibold mb-3">
                          $
                          {groupedPaymentsArray
                            .reduce((sum, payment) => sum + payment.monto, 0)
                            .toLocaleString("en-US", {
                              maximumFractionDigits: 0,
                            })}
                        </h3>

                        <Chart
                          options={{
                            chart: {
                              type: "donut",
                              events: {
                                click: function (event, chartContext, config) {
                                  const pieSliceElement = event.target;

                                  const mouseoverEvent = new Event("mouseover", {
                                    bubbles: true,
                                    cancelable: true,
                                  });
                                  pieSliceElement.dispatchEvent(mouseoverEvent);

                                  const mousemoveEvent = new MouseEvent("mousemove", {
                                    view: window,
                                    bubbles: true,
                                    cancelable: true,
                                    clientX: event.clientX,
                                    clientY: event.clientY,
                                  });
                                  pieSliceElement.dispatchEvent(mousemoveEvent);

                                  setTimeout(() => {
                                    document
                                      .querySelector(".apexcharts-tooltip")
                                      .classList.add("apexcharts-active");
                                  }, 100);
                                },
                              },
                            },
                            labels: groupedPaymentsArray.map((payment) => `Mesa ${payment.mesa}`),
                            colors: groupedPaymentsArray.map(
                              () =>
                                `#${Math.floor(Math.random() * 16777215)
                                  .toString(16)
                                  .padStart(6, "0")}`
                            ),
                            dataLabels: {
                              enabled: false,
                            },
                            legend: {
                              show: false,
                            },
                            tooltip: {
                              enabled: true,
                              style: {
                                fontSize: "22px",
                                fontFamily: "Arial",
                              },
                              formatter: (value, { series, seriesIndex, dataIndex, w }) => {
                                const mesa = groupedPaymentsArray[dataIndex].mesa;
                                const monto = groupedPaymentsArray[dataIndex].monto;
                                return `Mesa ${mesa}: $${monto.toFixed(2)}`;
                              },
                            },
                            stroke: {
                              width: 0,
                            },
                          }}
                          series={groupedPaymentsArray.map((payment) => Number(payment.monto.toFixed(2)))}
                          type="donut"
                          width="420"
                        />
                      </div>
                    </div>
                </div>

                </div>
    )
}
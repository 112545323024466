import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import AuthOverlay from "../components/AuthOverlay";
import { ExclamationTriangle, Lock } from "react-bootstrap-icons";
import {
  Eye,
  EyeFill
} from "react-bootstrap-icons";

function Login() {
  const navigate = useNavigate();
  const [password, setPassword] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [authSuccess, setAuthSuccess] = useState(false);
  const [showDefaultLogo, setShowDefaultLogo] = useState(true);
  const [isFlipped, setIsFlipped] = useState(false);
  const [error, setError] = useState(null);
  const [email, setEmail] = useState('');
  const [isValid, setIsValid] = useState(true);
  const [showPassword, setShowPassword] = useState(false);

  const preloadIMG = (srcArray) => {
    srcArray.forEach((src) => {
      const img = new Image();
      img.src = src;
    });
  };

  useEffect(() => {
    preloadIMG([
      'tippit-owner-woman.webp',
      'tippit-waiter.webp'
    ]);
  }, []);


  const handleFocus = () => {
    setShowDefaultLogo(false);
  };

  const handleBlur = () => {
    setShowDefaultLogo(true);
  };

  const handlePasswordRecovery = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    try {
      const role = "Restaurante";
      const response = await fetch(`https://tippitqr.com/enviar/email/recuperacion?email=${email}&rol=${role}`, {
        method: "GET",
      });

      if (!response.ok) {
        if (response.status === 404) {
          throw new Error("No se encontró el usuario. Por favor, verifica la dirección de email (sos Moz@ o Dueñ@?)");
        } else {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
      }

    } catch (error) {
      setError(error);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsSubmitting(true);

    const role = "Duenio";
    const formData = new URLSearchParams();
    formData.append("username", email);
    formData.append("password", password);
    formData.append("rol", role);

    try {
      const response = await fetch("https://tippitqr.com/userdb/login", {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: formData,
      });

      if (!response.ok) {
        if (response.status === 400) {
          throw new Error(`Error! Revisa tu email/clave (eras Moz@ o Dueñ@?)`);
        } else if (response.status === 422) {
          throw new Error(`Llena los dos campos por favor!`);
        } else {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
      }

      const data = await response.json();
      console.log("Autenticación exitosa:", data);
      sessionStorage.setItem("token", data.access_token);
      sessionStorage.setItem("rol", role);
      setAuthSuccess(true);

      setTimeout(() => {
        navigate("/dashboard");
      }, 1000);
    } catch (error) {
      console.error("Error during login:", error);
      if (error.name === "TypeError") {
        setError(
          new Error("Hubo un error de red. Por favor, inténtalo de nuevo.")
        );
      } else {
        setError(error);
      }
    }
  };

  const validateEmail = (email) => {
    const re = /^[a-zA-Z0-9_.-]+$/;
    return re.test(email);
  };

  const handleChange = (event) => {
    const { value } = event.target;
    setEmail(value);
    setIsValid(validateEmail(value));
  };



  return (
    <div className="flex h-screen overflow-hidden">
      <div
        className={`md:flex-1 w-full flex justify-center items-center order-last`}
      >
        <div
          className={`transition-all transform md:translate-x-10 opacity-100`}
        >
          <div className="relative w-[250px] h-[65px] mx-auto mb-5 mt-[-40%]">
            <img
              src="Tippit-logo-Texto-1024x242.png"
              className={`absolute 
                          inset-0 
                          transition-opacity 
                          duration-200 
                          ease-in-out ${showDefaultLogo ? "opacity-100" : "opacity-0"
                }`}
              alt="Tippit Logo"
            />
            <img
              src="Tippit-logo-Texto-1024x242-hidden.png"
              className={`absolute 
                          inset-0 
                          transition-opacity 
                          duration-200 
                          ease-in-out ${showDefaultLogo ? "opacity-0" : "opacity-100"
                }`}
              alt="Tippit Logo"
            />
          </div>

          <div
            className={`
    ${isFlipped ? "flipped" : ""}
    transition-transform
    duration-500
    backface-visibility-hidden
  `}
            id="loginView"
          >
            <div className="flipper w-[95%] mx-auto lg:w-full">
              <div className={`front
    p-7 
    bg-white 
    rounded-xl 
    shadow-lg     
    w-full
    md:w-96
    
    relative
    overflow-hidden
    transform
    transition-all
    duration-400
    
    ${isFlipped ? "invisible" : "visible"}`}
              >
                <form onSubmit={handleSubmit}>
                  <div
                    className={`enviando ${isSubmitting
                      ? "w-full h-full visible"
                      : "w-0 h-0 invisible"
                      } ${authSuccess
                        ? "fixed w-screen h-screen z-1000 transition-opacity duration-1000"
                        : ""
                      } bg-[#FF5EA3] absolute z-10 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 transition-all duration-3000 ease-in-out flex items-center justify-center`}
                  >
                    <div className="flex flex-col items-center">
                      <span className="text-white px-5 text-lg font-medium text-center">
                        {isSubmitting && !authSuccess && !error ? (
                          <img
                            src="/gooey-balls-2.svg"
                            className="mx-auto"
                            draggable="false"
                            alt=""
                          />
                        ) : (
                          <img
                            src="/gooey-balls-2.svg"
                            className="mx-auto"
                            draggable="false"
                            alt=""
                          />
                        )}
                        {error ? error.message : "Entrando..."}
                      </span>
                      <button
                        type="button"
                        className="text-white flex items-center px-5 text-lg font-medium mt-4"
                        onClick={() => {
                          setIsSubmitting(false);
                          setAuthSuccess(false);
                          setError(null);
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          className="bi bi-arrow-left-short"
                          viewBox="0 0 16 16"
                        >
                          <path
                            fillRule="evenodd"
                            d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5"
                          />
                        </svg>
                        Volver
                      </button>
                    </div>
                  </div>

                  <div className="relative">
                    {!isValid && <ExclamationTriangle className='text-red-500 text-lg absolute right-2 mt-3 mr-2' />}
                    <input
                      type="text"
                      id="emailOwner"
                      name="email"
                      placeholder="Username"
                      value={email}
                      onChange={handleChange}
                      className="w-full 
                              p-2 
                              mb-4 
                              border 
                              rounded 
                              focus:outline-none 
                              focus:border-pink-500"
                      autoComplete="username"
                    />
                  </div>

                  <div className="relative w-full mb-4 input-lock">
                    <input
                      type={showPassword ? "text" : "password"}
                      placeholder="Clave"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      className="w-full 
             p-2
             pl-9 
             border 
             rounded 
             focus:outline-none 
             focus:border-pink-500"
                      autoComplete="password"
                      onFocus={handleFocus}
                      onBlur={handleBlur}
                    />
                    <div
                      onClick={() => setShowPassword(!showPassword)}
                      className="cursor-pointer absolute right-4 top-3"
                    >
                      {showPassword ? <EyeFill width={20} height={20} /> : <Eye width={20} height={20} />}
                    </div>
                    <Lock className="absolute left-3 top-1/2 transform -translate-y-1/2 h-5 w-5 text-gray-300" />
                  </div>

                  <button
                    type="submit"
                    className="
                            transition:all 
                            duration-300 
                            ease-in-out 
                            w-full 
                            px-2 
                            py-4 
                          text-white 
                            font-semibold 
                          bg-[#FF5EA3] 
                            rounded-lg 
                          hover:bg-[#FF7DF2] 
                            hover:scale-105 p-3"
                  >
                    Entrar
                  </button>

                  <p className="mt-4 text-center">
                    <a
                      href="#!"
                      className="
                       text-[#FF5EA3] 
                       hover:text-pink-600 
                         hover:underline"
                      onClick={() => {
                        setIsFlipped(!isFlipped);
                      }}
                    >
                      Olvide mi clave
                    </a>
                  </p>
                </form>
              </div>
              <div
                className={`back
                            p-7 
                            bg-white 
                            rounded-xl 
                            shadow-lg
                            
                            w-full
                            md:w-96
                            relative
                            overflow-hidden
                            transform`}
              >
                <div className={"block"}>
                  <h2 className="text-md font-semibold mb-2">Olvide mi Clave</h2>

                  <form
                    className="mb-4 
                                text-lg 
                                "
                    onSubmit={handlePasswordRecovery}
                  >

                    <div
                      className={`enviando ${isSubmitting
                        ? "w-full h-full visible"
                        : "w-0 h-0 invisible"
                        } ${authSuccess
                          ? "fixed w-screen h-screen z-1000 transition-opacity duration-1000"
                          : ""
                        } bg-[#FF5EA3] absolute z-10 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 transition-all duration-3000 ease-in-out flex items-center justify-center`}
                    >
                      <div className="flex flex-col items-center">
                        <span className="text-white px-5 text-lg font-medium text-center">
                          {isSubmitting && !authSuccess && !error ? (
                            <img
                              src="/gooey-balls-2.svg"
                              className="mx-auto"
                              draggable="false"
                              alt=""
                            />
                          ) : (
                            <img
                              src="/gooey-balls-2.svg"
                              className="mx-auto"
                              draggable="false"
                              alt=""
                            />
                          )}
                          {error ? error.message : "Correo de recuperación enviado. Por favor, revisa tu bandeja de entrada."}
                        </span>
                        <button
                          type="button"
                          className="text-white flex items-center px-5 text-lg font-medium mt-4"
                          onClick={() => {
                            setIsSubmitting(false);
                            setAuthSuccess(false);
                            setError(null);
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            className="bi bi-arrow-left-short"
                            viewBox="0 0 16 16"
                          >
                            <path
                              fillRule="evenodd"
                              d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5"
                            />
                          </svg>
                          Volver
                        </button>
                      </div>
                    </div>



                    <p className="text-sm text-gray-600 mb-4">
                      Ingresa tu dirección de correo electrónico asociada a tu
                      cuenta para recuperar tu clave.
                    </p>
                    <div className="mb-4">
                      <label
                        htmlFor="email"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Correo Electrónico
                      </label>
                      <input
                        type="email"
                        id="emailRetrieve"
                        name="email"
                        onChange={handleChange}
                        value={email}
                        className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-pink-500 focus:border-pink-500 sm:text-sm"
                        placeholder="correo@example.com"
                        required
                      />

                    </div>
                    <button
                      type="submit"
                      onClick={() => {
                        setIsSubmitting(false);
                        setAuthSuccess(false);
                        setError(null);
                      }}

                      className="w-full text-base text-white font-bold py-4 px-4 rounded-lg focus:outline-none focus:shadow-outline bg-[#FF5EA3] hover:bg-[#FF7DF2]"
                    >
                      Recuperar Clave
                    </button>


                  </form>
                  <button
                    className="flex 
                                items-center
                                mt-5
                                text-[#FF5EA3] 
                                hover:text-pink-600 
                                hover:underline
                                font-normal
                                text-sm
                                
                                "
                    onClick={() => setIsFlipped(!isFlipped)}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-arrow-left-short"
                      viewBox="0 0 16 16"
                    >
                      <path
                        fillRule="evenodd"
                        d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5"
                      />
                    </svg>
                    Volver
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`flex-1 flex flex-col justify-end portrait:hidden landscape:md:flex lg:flex bg-owner transition-all duration-500 ease-in-out`}
      >
        <img
          src={"tippit-owner-woman.webp"}
          draggable="false"
          className={`w-full transform imageLogin transition-all duration-500 animate-enterOwner`}
          alt=""
        />
      </div>
      <AuthOverlay isSubmitting={isSubmitting} authSuccess={authSuccess} />
    </div>
  );
}

export default Login;
